import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import "antd/dist/antd.min.css";
import MainHelp from "./components/pages/ContentPages/MainHelp/MainHelp";
import Nav from "./components/pages/common/Nav";
import ApppFooter from "./components/pages/common/AppFooter";
import { Layout } from "antd";
import FAQ from "./components/pages/ContentPages/FAQ/FAQ";
import ReportBug from "./components/pages/ContentPages/ReportBug/ReportBug";
// import { Tutorial } from "./components/pages/ContentPages/Tutorial/Tutorial";
// import { PlayVideo } from "./components/pages/ContentPages/Tutorial/PlayVideo";
import SearchResult from "./components/pages/SearchResult";
import GettingStarted from "./components/pages/ContentPages/GettingStarted/GettingStarted";
import MessagesAndCalls from "./components/pages/ContentPages/MessageandCalls/MessagesAndCalls";
import TaskManagement from "./components/pages/ContentPages/TaskManagement/TaskManagement";
import Meetings from "./components/pages/ContentPages/Meetings/Meetings";
import Automation from "./components/pages/ContentPages/Automation/Automation";
import Calendar from "./components/pages/ContentPages/Calendar/Calendar";
import Usinganydone from "./components/pages/ContentPages/GettingStarted/components/UsingAnydone/Usinganydone";
import CreatingGroup from "./components/pages/ContentPages/MessageandCalls/components/CreatingGroups/CreatingGroup";
import MessageExperience from "./components/pages/ContentPages/MessageandCalls/components/MessageExperience/MessageExperience";
import ManageMessages from "./components/pages/ContentPages/MessageandCalls/components/ManageMessages/ManageMessages";
import MessageStyling from "./components/pages/ContentPages/MessageandCalls/components/MessageStyling/MessageStyling";
import CreateMeeting from "./components/pages/ContentPages/Meetings/components/CreateMeeting/CreateMeeting";
import ManagingCalendar from "./components/pages/ContentPages/Calendar/components/ManagingCalendar/ManagingCalendar";
import AnydoneZipy from "./components/pages/ContentPages/Automation/components/AnydoneZipy/AnydoneZipy";
import AnydoneBot from "./components/pages/ContentPages/Automation/components/AnydoneBot/AnydoneBot";
import AutoTicketCreate from "./components/pages/ContentPages/Automation/components/AutoTicketCreate/AutoTicketCreate";
import ManageGroups from "./components/pages/ContentPages/MessageandCalls/components/ManageGroups/ManageGroups";
import InvitingAndManagingMembers from "./components/pages/ContentPages/GettingStarted/components/InvitingAndManagingMembers/InvitingAndManagingMembers";
import InvitingAndManagingGuests from "./components/pages/ContentPages/GettingStarted/components/InvitingAndManagingGuests/InvitingAndManagingGuests";
import InvitingAndManagingCompany from "./components/pages/ContentPages/GettingStarted/components/InvitingAndManagingCompany/InvitingAndManagingCompany";
import ProfilePrefences from "./components/pages/ContentPages/GettingStarted/components/ProfilePrefences/ProfilePrefences";
import ManageProfile from "./components/pages/ContentPages/GettingStarted/components/ManageProfile/ManageProfile";
import ManageNotification from "./components/pages/ContentPages/GettingStarted/components/ManageNotification/ManageNotification";
import ManageAccount from "./components/pages/ContentPages/GettingStarted/components/ManageAccount/ManageAccount";
import Groups from "./components/pages/ContentPages/MessageandCalls/components/Groups/Groups";
import SettingAndPreferences from "./components/pages/ContentPages/GettingStarted/components/SettingAndPreferences/SettingAndPreferences";
import WorkspaceSetting from "./components/pages/ContentPages/GettingStarted/components/WorkspaceSetting/WorkspaceSetting";
import Calls from "./components/pages/ContentPages/MessageandCalls/components/Calls/Calls";
import ManageActivity from "./components/pages/ContentPages/MessageandCalls/components/ManageActivity/ManageActivity";
import Whiteboard from "./components/pages/ContentPages/Meetings/components/Whiteboard/Whiteboard";
import useSessionFromCookie from "./components/Api/useSessionFromCookie";
import CreatingOrganizingProject from "./components/pages/ContentPages/TaskManagement/components/CreatingOrganizingProject/CreatingOrganizingProject";
import ManageProject from "./components/pages/ContentPages/TaskManagement/components/ManageProject/ManageProject";
import MembersAndPermissionSettings from "./components/pages/ContentPages/TaskManagement/components/MembersAndPermissionSettings/MembersAndPermissionSettings";
import InviteGuestAndCompany from "./components/pages/ContentPages/TaskManagement/components/InviteGuestAndCompany/InviteGuestAndCompany";
import CreatingAndManagingTaskFolder from "./components/pages/ContentPages/TicketManagement/components/CreatingAndManagingTaskFolder/CreatingAndManagingTaskFolder";
import CreatingAndManagingTicket from "./components/pages/ContentPages/TicketManagement/components/CreatingAndManagingTicket/CreatingAndManagingTicket";
import AutomatedReply from "./components/pages/ContentPages/Automation/components/AutomatedReply/AutomatedReply";
import ScrollToTop from "./components/utils/ScrollToTop";
import TicketManagement from "./components/pages/ContentPages/TicketManagement/TicketManagement";
import Forms from "./components/pages/ContentPages/TaskManagement/components/Forms/Forms";
import Papers from "./components/pages/ContentPages/TaskManagement/components/Papers/Papers";
import MeetAssist from "./components/pages/ContentPages/MessageandCalls/components/MeetAssist/MeetAssist";
import RoadMap from "./components/pages/ContentPages/TaskManagement/components/RoadMap/RoadMap";
import TicketDashboard from "./components/pages/ContentPages/TicketManagement/components/TicketDashboard/TicketDashboard";
import TicketReport from "./components/pages/ContentPages/TicketManagement/components/TicketReport/TicketReport";
import TicketWorkflow from "./components/pages/ContentPages/TicketManagement/components/TicketWorkflow/TicketWorkflow";
import Collab from "./components/pages/ContentPages/MessageandCalls/components/collab/Collab";
import Checklist from "./components/pages/ContentPages/TicketManagement/components/checklist/CheckList";
import SubTask from "./components/pages/ContentPages/TicketManagement/components/subtasks/Subtask";
import CustomField from "./components/pages/ContentPages/TicketManagement/components/customfield/CustomField";
import { Cuscontent } from "./components/pages/ContentPages/TicketManagement/components/customfield/content";
import SprintFolder from "./components/pages/ContentPages/TicketManagement/components/CreatingAndManagingSprintFolder/SprintFolder";
import AutoAssign from "./components/pages/ContentPages/TicketManagement/components/Auto assign/AutoAssign";
import AnydoneFlowcess from "./components/pages/ContentPages/Automation/components/AnydoneFlowcess/AnydoneFlowcess";
import AnydoneCreation from "./components/pages/ContentPages/Automation/components/AnydoneCreation/AnydoneCreation";
import AnydoneApp from "./components/pages/ContentPages/Automation/components/AnydoneApp/AnydoneApp.jsx";

import AnydoneCreationFlowcess from "./components/pages/ContentPages/Automation/components/AnydoneCreationFlowcess/AnydoneCreationFlowcess.jsx";
import RunningFlowcess from "./components/pages/ContentPages/Automation/components/RunningFlowcess/RunningFlowcess.jsx";
import AnydoneCreationProcess from "./components/pages/ContentPages/Automation/components/AnydoneCreationProcess/AnydoneCreationProcess.jsx";
import AutomationTools from "./components/pages/ContentPages/Automation/components/AutomationTools/AutomationTools.jsx";
const { Header, Footer, Content } = Layout;

function App() {
  const [isPastWaypoint, setIsPastWaypoint] = useState(false);

  const handleWaypointEnter = () => {
    setIsPastWaypoint(false);
  };

  const handleWaypointLeave = () => {
    setIsPastWaypoint(true);
  };

  const { loading, userSession } = useSessionFromCookie();

  return (
    <BrowserRouter>
      <Layout className="main-layout">
        <Header>
          <Nav
            isPastWaypoint={isPastWaypoint}
            sessionLoading={loading}
            userSession={userSession}
          />
        </Header>
        <Content>
          <Routes>
            <Route
              path="/"
              element={
                <MainHelp
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route path="search" element={<SearchResult />} />

            <Route
              path="getting-started"
              element={
                <GettingStarted
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="getting-started/using-anydone"
              element={
                <Usinganydone
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="messages-and-calls/creating-group"
              element={
                <CreatingGroup
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="messages-and-calls/manage-groups"
              element={
                <ManageGroups
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="messages-and-calls/meetassist"
              element={
                <MeetAssist
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="project-management/forms"
              element={
                <Forms
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="project-management/papers"
              element={
                <Papers
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="getting-started/inviting-and-managing-members"
              element={
                <InvitingAndManagingMembers
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="getting-started/inviting-and-managing-externals"
              element={
                <InvitingAndManagingGuests
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="getting-started/inviting-and-managing-company"
              element={
                <InvitingAndManagingCompany
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="getting-started/profile-preferences"
              element={
                <ProfilePrefences
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="getting-started/manage-profile"
              element={
                <ManageProfile
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="getting-started/manage-account"
              element={
                <ManageAccount
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="getting-started/manage-notification"
              element={
                <ManageNotification
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />

            <Route
              path="messages-and-calls"
              element={
                <MessagesAndCalls
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="messages-and-calls/message-experience"
              element={
                <MessageExperience
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="messages-and-calls/manage-messages"
              element={
                <ManageMessages
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="messages-and-calls/message-edit"
              element={
                <MessageStyling
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="messages-and-calls/groups"
              element={
                <Groups
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="getting-started/setting-and-preferences"
              element={
                <SettingAndPreferences
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="getting-started/workspace-setting"
              element={
                <WorkspaceSetting
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="messages-and-calls/calls"
              element={
                <Calls
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="messages-and-calls/collab"
              element={
                <Collab
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="messages-and-calls/manage-activity"
              element={
                <ManageActivity
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />

            <Route
              path="project-management"
              element={
                <TaskManagement
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="project-management/creating-and-organizing-project"
              element={
                <CreatingOrganizingProject
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="project-management/roadmap"
              element={
                <RoadMap
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="project-management/manage-project"
              element={
                <ManageProject
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="project-management/project-members-and-permission-settings"
              element={
                <MembersAndPermissionSettings
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="project-management/invite-external-and-company-to-project"
              element={
                <InviteGuestAndCompany
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="ticket-management/creating-and-managing-task-folder"
              element={
                <CreatingAndManagingTaskFolder
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="ticket-management/creating-and-managing-ticket"
              element={
                <CreatingAndManagingTicket
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />

            <Route
              path="ticket-management/ticket-dashboard"
              element={
                <TicketDashboard
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />

            <Route
              path="ticket-management/ticket-report"
              element={
                <TicketReport
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />

            <Route
              path="ticket-management/ticket-workflow"
              element={
                <TicketWorkflow
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />

            <Route
              path="ticket-management/checklist"
              element={
                <Checklist
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />

            <Route
              path="ticket-management/subtasks"
              element={
                <SubTask
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />

            <Route
              path="ticket-management/custom-field"
              element={
                <CustomField
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />

            <Route
              path="ticket-management/creating-managing-sprint-folders"
              element={
                <SprintFolder
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />

            <Route
              path="ticket-management/auto-assign"
              element={
                <AutoAssign
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />

            <Route
              path="meetings"
              element={
                <Meetings
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="meetings/whiteboard"
              element={
                <Whiteboard
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="meetings/creating-meetings"
              element={
                <CreateMeeting
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="automation"
              element={
                <Automation
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="automation/anydone-zipy"
              element={
                <AnydoneZipy
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="automation/anydone-flowcess"
              element={
                <AnydoneFlowcess
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="automation/anydone-creation"
              element={
                <AnydoneCreation
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="automation/anydone-creation-process"
              element={
                <AnydoneCreationProcess
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="automation/automation-tools"
              element={
                <AutomationTools
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="automation/anydone-app"
              element={
                <AnydoneApp
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="automation/anydone-running-flowcess"
              element={
                <RunningFlowcess
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="automation/anydone-create-flowcess"
              element={
                <AnydoneCreationFlowcess
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="automation/anydone-bot"
              element={
                <AnydoneBot
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="automation/auto-ticket-create"
              element={
                <AutoTicketCreate
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="automation/automated-reply"
              element={
                <AutomatedReply
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="calendar"
              element={
                <Calendar
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="calendar/managing-calendar"
              element={
                <ManagingCalendar
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />

            {/* <Route
							path="tutorial"
							element={
								<Tutorial
									handleWaypointEnter={handleWaypointEnter}
									handleWaypointLeave={handleWaypointLeave}
								/>
							}
						/>
						<Route
							path="play-video"
							element={
								<PlayVideo
									handleWaypointEnter={handleWaypointEnter}
									handleWaypointLeave={handleWaypointLeave}
								/>
							}
						/> */}
            <Route
              path="faq/anydone-business-management-platform"
              element={
                <FAQ
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
            <Route
              path="report-bug"
              element={
                <ReportBug
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />

            <Route
              path="ticket-management"
              element={
                <TicketManagement
                  handleWaypointEnter={handleWaypointEnter}
                  handleWaypointLeave={handleWaypointLeave}
                />
              }
            />
          </Routes>
        </Content>
        <Footer>
          <ApppFooter />
        </Footer>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
